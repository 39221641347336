body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  background-image: url('/public/ai-brain.png'); /* Path to your image */
  background-size: cover; /* Ensures the image covers the header */
  background-position: center; /* Centers the image in the header */
  background-repeat: no-repeat;
  color: #333;
  position: relative;
}

.name {
  font-size: 4rem;
  color: #ffffff;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.tabs {
  display: flex;
  justify-content: center;
  padding: 1rem;
  background-color: #f9f9f9;
}

.tabs ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.tabs li {
  margin: 0 1rem;
}

.tabs a {
  text-decoration: none;
  font-weight: bold;
  color: #333;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.tabs .active a {
  background-color: #1c5b88;
  color: #fff;
}
.tabs a:hover {
  background-color: #e0e0e0;
}

.content {
  width: 80%; /* Takes up 80% of the page width */
  margin: 2rem auto; /* Centers and adds vertical spacing */
  padding: 2rem; /* Adds padding inside content area */
  background-color: #fff; /* Optional: different color for content */
  border-radius: 8px; /* Optional: adds rounded corners */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  Optional: subtle shadow */
}

.side-image {
  width: 100px; /* Adjust the size of the images */
  margin: 0 20px; /* Adds space between the image and the name */
}