.about {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    gap: 5rem; /* Space between image and text */
    max-width: 80%;
    margin: 0 auto;
  }
  
  .profile-image {
    width: 300px; /* Adjust size as needed */
    height: 300px;
    border-radius: 50%; /* Makes the image circular */
    object-fit: cover; /* Keeps the aspect ratio */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for style */
  }
  
  .description {
    max-width: 600px; /* Controls text width for readability */
  }
  
  .description h1 {
    margin-bottom: 2rem;
  }
  
  .description p {
    margin-bottom: 1rem;
    line-height: 1.6;
    text-align: justify; /* Justifies the text */
    font-size: 1.3rem; /* Larger paragraph text */
  }

  .highlight {
    font-weight: bold;
    color: #3190d5; /* Blue color for emphasis */
  }
  